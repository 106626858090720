<template>
  <div class='proposal-overview flex flex-col justify-between min-h-screen'>
    <Header/>
    <Content class='mb-auto'>
      <div class="text-lg max-w-prose mx-auto">
        <h1>
          <span
            class="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase"
            >overview</span
          >
          <span
            class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
            >Research proposal</span
          >
        </h1>
        <p class="mt-8 text-xl text-gray-500 leading-8">
          All page lengths refer to single-sided pages. Proposals with any
          section exceeding any page limit will not be reviewed. Requirements
          and structure of the document by which the applicant applies for a
          grant from TumorSHOT LLC:
        </p>
      </div>
      <div class="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <ol role="list">
          <li>Project name</li>
          <li>Name of the main researcher</li>
          <li>Names of co-researchers</li>
          <li>
            Name of workplace, address and connection (telephones, fax, e-mail)
          </li>
          <li>
            Brief characteristics of the project, description of the project
            (limit 5,000 characters)
          </li>
          <li>Implementation output of the project</li>
          <li>Literature/ publications</li>
        </ol>
      </div>
    </Content>
    <Footer />
  </div>
</template>

<script>

import Header from '@/components/Header'
import Content from '@/components/Content'
import Footer from '@/components/Footer'

export default {
  name: 'ProposalOverview',
  components: {
    Header,
    Content,
    Footer
  },
  mounted() {
    window.scrollTo(0,0)
  }
}
</script>
